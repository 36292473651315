import React, {
    ChangeEvent,
    FunctionComponent,
    ReactText,
    useEffect,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    ActionType,
    ApplianceAction,
    ApplianceDeactivationEntry,
    ApplianceDeactivationReason,
    ApplianceDto,
    ApplianceMaintenanceScheduleDto,
    BasicApplianceInformation,
    ManageApplianceStore,
} from '../../store/manage-appliances/manageApplianceReducer';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    cancelBlindMaintenance,
    cancelInstallationVerification,
    deleteMaintenanceSchedule,
    loadAppliance,
    loadLocationWithAppliances,
    startBlindMaintenance,
    startClientInstallation,
    startInstallationVerification,
    toggleAppliance,
    updateBlindMaintenance,
} from '../../store/manage-appliances/manageApplianceActions';
import { Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import styles from '../manage-appliance/ManageApplianceForm.module.css';
import SuccessFeedback from '../SuccessFeedback';
import ErrorFeedback from '../ErrorFeedback';
import { resetViews } from '../../store/genericActions';
import { goTo } from '../../store/router/routerActions';
import { Route } from '../../Router';
import {
    LaundryRoomNotesDto,
    loadLaundryRoomNotes,
    setLaundryRoomNotes,
} from '../../store/manage-laundry-room/manageLaundryRoomActions';
import moment from 'moment';
import HorizontalApplianceSelect, {
    DisplayedApplianceInfo,
} from '../../components/manage-appliance/HorizontalApplianceSelect';
import { useHistory, useLocation } from 'react-router-dom';
import { parseUrlParamsFromSearchString } from '../../util/url';
import LaundryRoomAnchorCard, {
    DisplayedLaundryRoomInfo,
} from './LaundryRoomAnchorCard';
import {
    applianceIdReqParam,
    laundryRoomIdReqParam,
} from '../../util/constants';
import { mapIncidentTypeToShortText } from '../../store/manage-laundry-room/manageLaundryRoomUtils';
import { ModalType } from '../../components/modal/RootModal';
import { ModalParameters, openModal } from '../../store/modal/modalActions';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import {
    formatDurationAsHours,
    formatRepeatInterval,
} from './UpsertMaintenanceSchedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SetSupportsScan2WashParams } from '../../components/modal/SetSupportsScan2WashForLaundryRoomModal';
import { SetNotMyLaundryParams } from '../../components/modal/SetNotMyLaundryAllowedModal';

interface Props extends ManageApplianceStore {
    manageLaundryRoomsStore: ManageLaundryRoomStore;
    loadAppliance: (
        applianceId: ReactText,
        loadedLocationId?: number | undefined
    ) => any;
    loadLocationWithAppliances: (locationId: string) => any;
    toggleAppliance: (data: ToggleApplianceDto) => any;
    startInstallationVerification: (data: StartVerificationDto) => any;
    cancelInstallationVerification: (data: CancelVerificationDto) => any;
    startBlindMaintenance: (data: BlindMaintenanceStartDto) => any;
    cancelBlindMaintenance: (data: BlindMaintenanceCancelDto) => any;
    updateBlindMaintenance: (data: BlindMaintenanceUpdateDto) => any;
    deleteMaintenanceSchedule: (data: ApplianceMaintenanceScheduleDto) => void;
    startClientInstallation: (applianceId: ReactText) => any;
    clearView: () => void;
    goTo: (
        route: Route,
        param?: string | number,
        requestParams?: Map<string, any>
    ) => any;
    openModal: (params: ModalParameters<any>) => any;
    loadLaundryRoomNotes: (locationId: ReactText) => any;
    setLaundryRoomNotes: (
        locationId: ReactText,
        notes: LaundryRoomNotesDto,
        onDone?: () => void
    ) => void;
}

interface IdSearchParameters {
    id: ReactText;
}

interface ToggleApplianceParameters {
    comment: string;
    isEnabled: boolean;
    updateFilterReplacementTimestamp: boolean;
    dischargePumpIncident: boolean;
    deactivationReason?: ApplianceDeactivationReason;
}

interface StartVerificationParameters {
    comment: string;
    sendReportCp: boolean;
    sendReportCs: boolean;
    loadingTimeHours: string;
}

interface CancelVerificationParameters {
    comment: string;
}

interface BlindMaintenanceParameters {
    comment: string;
    startTime: Date;
    durationHours: string;
    lintFilterReplacement?: boolean;
    targetIsEnabled: boolean;
    reason: ApplianceDeactivationReason;
}

interface BlindMaintenanceCancelParameters {
    comment: string;
    lintFilterReplacement?: boolean;
    targetIsEnabled: boolean;
}

interface BlindMaintenanceUpdateParameters {
    startTime?: Date;
    endTime?: Date;
    targetIsEnabled?: boolean;
}

enum LoadState {
    LaundryRoom = 'Laundry Room',
    Appliance = 'Appliance',
}

export interface ToggleApplianceDto
    extends IdSearchParameters,
        ToggleApplianceParameters {}

export interface StartVerificationDto
    extends IdSearchParameters,
        StartVerificationParameters {}

export interface CancelVerificationDto
    extends IdSearchParameters,
        CancelVerificationParameters {}

export interface BlindMaintenanceStartDto
    extends IdSearchParameters,
        BlindMaintenanceParameters {}

export interface BlindMaintenanceCancelDto
    extends IdSearchParameters,
        BlindMaintenanceCancelParameters {}

export interface BlindMaintenanceUpdateDto
    extends IdSearchParameters,
        BlindMaintenanceUpdateParameters {}

function translateDeactivationReason(
    reason?: ApplianceDeactivationReason
): string {
    if (!reason) {
        return '-';
    }
    switch (reason) {
        case ApplianceDeactivationReason.TECHNICAL_DEFECT:
            return 'Technischer Defekt';
        case ApplianceDeactivationReason.CLEANING:
            return 'Reinigung';
        case ApplianceDeactivationReason.INSTALLATION:
            return 'Installation';
        case ApplianceDeactivationReason.PERMANENT_DEACTIVATION:
            return 'Dauerhafte Deaktivierung';
        case ApplianceDeactivationReason.OTHER:
            return 'Sonstiges';
        default:
            return reason;
    }
}

export function renderDeactivationReasonOptions() {
    return Object.values(ApplianceDeactivationReason).map((it) => (
        <option value={it} key={it}>
            {translateDeactivationReason(it)}
        </option>
    ));
}

function timestampToHrDate(timestamp: number) {
    return new Date(timestamp).toLocaleString('de-DE');
}

function isWashingMachine(appliance?: ApplianceDto | null): boolean {
    return !!appliance && appliance.appliance_type === 'WASHING_MACHINE';
}

function isDryer(appliance?: ApplianceDto | null): boolean {
    return !!appliance && appliance.appliance_type === 'DRYER';
}

const ManageApplianceForm: FunctionComponent<Props> = (props) => {
    const history = useHistory();
    const requestParams = parseUrlParamsFromSearchString(
        useLocation().search.substring(1)
    );
    const paramApplianceId = requestParams.get(applianceIdReqParam);
    const existingApplianceId = props.appliance
        ? String(props.appliance.appliance_id)
        : undefined;
    const providedApplianceId =
        existingApplianceId === undefined
            ? paramApplianceId
            : existingApplianceId;
    let urlParamLocationId = requestParams.get(laundryRoomIdReqParam);
    const loadState = providedApplianceId
        ? LoadState.Appliance
        : LoadState.LaundryRoom;

    const lintFilterReplacementEnabled: boolean = isDryer(props.appliance);

    useEffect(() => {
        if (paramApplianceId) {
            LoadByApplianceId(paramApplianceId);
        }
    }, [paramApplianceId]);
    useEffect(() => {
        if (urlParamLocationId) {
            props.loadLocationWithAppliances(urlParamLocationId);
        }
    }, [urlParamLocationId]);

    let idParam = getIdSearchParam();
    const idSearchParameters: IdSearchParameters = {
        id: idParam,
    };

    const toggleApplianceParameters: ToggleApplianceParameters = {
        comment: '',
        isEnabled: false,
        updateFilterReplacementTimestamp: false,
        dischargePumpIncident: false,
        deactivationReason: ApplianceDeactivationReason.TECHNICAL_DEFECT,
    };

    const startVerificationParameters: StartVerificationParameters = {
        comment: '',
        sendReportCp: false,
        sendReportCs: true,
        loadingTimeHours: '16',
    };
    const cancelVerificationParameters: CancelVerificationParameters = {
        comment: '',
    };

    const startBlindMaintenanceParameters: BlindMaintenanceParameters = {
        comment: '',
        startTime: getDefaultDateTime(),
        durationHours: '9',
        lintFilterReplacement: undefined,
        targetIsEnabled: true,
        reason: ApplianceDeactivationReason.TECHNICAL_DEFECT,
    };

    const cancelBlindMaintenanceParameters: BlindMaintenanceCancelParameters = {
        comment: '',
        targetIsEnabled: true,
        lintFilterReplacement: undefined,
    };

    function handleCleaningInformationModel() {
        if (
            props.locationWithAppliances &&
            props.locationWithAppliances.location_information
        ) {
            props.openModal({
                type: ModalType.CHANGE_LOCATION_META_INFORMATION,
                data: {
                    metadata:
                        props.locationWithAppliances.location_information
                            .location_meta_data_dto,
                    locationId:
                        props.locationWithAppliances.location_information.id,
                },
            });
        }
    }

    function handleNeedsMonitoringOptionEdit() {
        if (
            props.locationWithAppliances &&
            props.locationWithAppliances.location_information
        ) {
            props.openModal({
                type: ModalType.CHANGE_MONITORING_FLAG_INFORMATION,
                data: {
                    needs_monitoring:
                        props.locationWithAppliances.location_information
                            .needs_monitoring,
                    locationId:
                        props.locationWithAppliances.location_information.id,
                },
            });
        }
    }

    function handleFeedbackModeEdit() {
        if (
            props.locationWithAppliances &&
            props.locationWithAppliances.location_information
        ) {
            props.openModal({
                type: ModalType.SELECT_FEEDBACK_MODE_QUESTION,
                data: {
                    laundryRoomId:
                        props.locationWithAppliances.location_information.id,
                    feedbackQuestionId:
                        props.locationWithAppliances.location_information
                            .feedback_question_id,
                },
            });
        }
    }

    function handleVisibilityDistanceEdit() {
        if (
            props.locationWithAppliances &&
            props.locationWithAppliances.location_information
        ) {
            props.openModal({
                type: ModalType.CHANGE_LOCATION_VISIBILITY_DISTANCE,
                data: {
                    distance:
                        props.locationWithAppliances.location_information
                            .visibility_distance,
                    locationId:
                        props.locationWithAppliances.location_information.id,
                },
            });
        }
    }

    function handlePrepaymentModeEdit() {
        if (
            props.locationWithAppliances &&
            props.locationWithAppliances.location_information
        ) {
            props.openModal({
                type: ModalType.CHANGE_PREPAYMENT_ALLOWED,
                data: {
                    prePaymentRequired:
                        props.locationWithAppliances.location_information
                            .pre_payment_required,
                    laundryRoomId:
                        props.locationWithAppliances.location_information.id,
                },
            });
        }
    }

    function handleControlStatusEdit() {
        if (props.appliance && props.appliance.monitoring) {
            props.openModal({
                type: ModalType.CHANGE_APPLIANCE_CONTROL_STATUS,
                data: {
                    monitoring: props.appliance.monitoring,
                },
            });
        }
    }

    function handleScan2WashEdit() {
        if (
            props.locationWithAppliances &&
            props.locationWithAppliances.location_information
        ) {
            const data: SetSupportsScan2WashParams = {
                supportsScan2Wash:
                    !!props.locationWithAppliances.location_information
                        .supports_scan2_wash,
                laundryRoomId:
                    props.locationWithAppliances.location_information.id,
            };
            props.openModal({
                type: ModalType.CHANGE_SCAN_2_WASH_SUPPORTED,
                data,
            });
        }
    }

    function handleNotMyLaundryEdit() {
        if (props.locationWithAppliances) {
            const data: SetNotMyLaundryParams = {
                notMyLaundryAllowed:
                    !!props.locationWithAppliances.location_information
                        .not_my_laundry_allowed,
                laundryRoomId:
                    props.locationWithAppliances.location_information.id,
            };
            props.openModal({
                type: ModalType.CHANGE_NOT_MY_LAUNDRY_ALLOWED,
                data,
            });
        }
    }

    function handleSetLaundryRoomNotes(notes: LaundryRoomNotesDto) {
        if (notes.laundry_room_id) {
            const locId = notes.laundry_room_id;
            props.setLaundryRoomNotes(locId, notes, () =>
                props.loadLocationWithAppliances(String(locId))
            );
        }
    }

    function getDefaultDateTime() {
        const date = new Date();
        if (date.getHours() > 7) {
            date.setDate(date.getDate() + 1);
        }
        date.setHours(7, 0, 0, 0);
        return date;
    }

    function renderBody() {
        function renderApplianceOverview() {
            if (
                props.locationWithAppliances &&
                props.locationWithAppliances.appliances.length === 0
            ) {
                return <></>;
            }
            return (
                <>
                    <span>
                        {searchHeader(
                            'Maschineninformationen',
                            loadState === LoadState.Appliance
                        )}
                        <div className={styles.infoContainer}>
                            {applianceDataDetails()}
                            {applianceUpdateForm()}
                        </div>
                        <ErrorFeedback apiErrors={props.actionErrors} />
                        {props.latestSuccessAction && (
                            <SuccessFeedback
                                message={createSuccessMessage(
                                    props.latestSuccessAction
                                )}
                            />
                        )}
                    </span>
                    <span>{renderApplianceHistoryList()}</span>
                </>
            );
        }

        if (!props.appliance || !props.locationWithAppliances) {
            return <></>;
        } else {
            return (
                <div>
                    <div>{laundryRoomOverview()}</div>
                    <div>{renderApplianceOverview()}</div>
                </div>
            );
        }
    }

    return (
        <div id="ManageApplianceForm">
            <div>
                <Formik
                    onSubmit={handleSubmitSearchByLaundryRoomId}
                    initialValues={idSearchParameters}
                >
                    {(formProps: FormikProps<IdSearchParameters>) => {
                        function handleSearchIdChange(
                            event: React.ChangeEvent<any>
                        ) {
                            if (
                                props.appliance ||
                                props.locationWithAppliances
                            ) {
                                props.clearView();
                            }
                            formProps.handleChange(event);
                        }

                        return (
                            <Form noValidate onSubmit={formProps.handleSubmit}>
                                <Form.Group
                                    controlId="id"
                                    style={{ marginRight: '0.5rem' }}
                                >
                                    <Form.Label>
                                        <h2>ID Suche</h2>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleSearchIdChange}
                                        value={String(formProps.values.id)}
                                    />
                                </Form.Group>
                                <div style={{ marginRight: '0.5rem' }}>
                                    {loadDetailsButton(
                                        LoadState.LaundryRoom,
                                        formProps
                                    )}
                                    {loadDetailsButton(
                                        LoadState.Appliance,
                                        formProps
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <span>
                    <ErrorFeedback apiErrors={props.loadingErrors} />
                </span>
                {renderBody()}
            </div>
        </div>
    );

    function getIdSearchParam(): ReactText {
        if (providedApplianceId) {
            return providedApplianceId;
        } else {
            return urlParamLocationId || '';
        }
    }

    function searchHeader(text: string, searched: boolean) {
        const circle = {
            height: '1rem',
            width: '1rem',
            'background-color': '#00B3E6',
            'border-radius': '50%',
            display: 'inline-block',
        };
        if (searched) {
            return (
                <h2>
                    {text} <span style={circle} />
                </h2>
            );
        } else {
            return <h2>{text}</h2>;
        }
    }

    function handleToggleApplianceSubmit(values: ToggleApplianceParameters) {
        if (!providedApplianceId) {
            return;
        }
        props.toggleAppliance({ id: String(providedApplianceId), ...values });
    }

    function handleStartVerificationSubmit(
        values: StartVerificationParameters
    ) {
        if (!providedApplianceId) {
            return;
        }
        props.startInstallationVerification({
            id: providedApplianceId,
            ...values,
        });
    }

    function handleCancelVerificationSubmit(
        values: CancelVerificationParameters
    ) {
        if (!providedApplianceId) {
            return;
        }
        props.cancelInstallationVerification({
            id: providedApplianceId,
            ...values,
        });
    }

    function handleStartBlindMaintenanceSubmit(
        values: BlindMaintenanceParameters
    ) {
        if (!providedApplianceId) {
            return;
        }
        props.startBlindMaintenance({ id: providedApplianceId, ...values });
    }

    function handleCancelBlindMaintenanceSubmit(
        values: BlindMaintenanceCancelParameters
    ) {
        if (!providedApplianceId) {
            return;
        }
        props.cancelBlindMaintenance({ id: providedApplianceId, ...values });
    }

    function handleUpdateBlindMaintenanceSubmit(
        values: BlindMaintenanceUpdateParameters
    ) {
        if (!providedApplianceId) {
            return;
        }
        props.updateBlindMaintenance({ id: providedApplianceId, ...values });
    }

    function handleStartClientInstallationSubmit() {
        if (!providedApplianceId) {
            return;
        }
        props.startClientInstallation(providedApplianceId);
    }

    function loadDetailsButton(
        elementLoadState: LoadState,
        formProps: FormikProps<IdSearchParameters>
    ) {
        let buttonText = '';
        let className = '';
        let action = LoadByAppliance;
        switch (elementLoadState) {
            case LoadState.Appliance: {
                buttonText = 'Appliance suchen';
                action = LoadByAppliance;
                className = 'btn btn-outline-secondary';
                break;
            }
            case LoadState.LaundryRoom: {
                buttonText = 'Waschraum suchen';
                action = LoadByLaundryRoom;
                className = 'btn btn-primary';
                break;
            }
        }
        return (
            <button
                type={'button'}
                className={className}
                style={{ marginRight: '0.5rem' }}
                onClick={action}
                disabled={props.loading || props.loadingLocationWithAppliances}
            >
                {buttonText}
            </button>
        );

        function LoadByAppliance() {
            const searchId = formProps.values.id;
            LoadByApplianceId(searchId);
        }

        function LoadByLaundryRoom() {
            const searchId = formProps.values.id;
            LoadByLaundryRoomId(searchId);
        }
    }

    function handleSubmitSearchByLaundryRoomId(values: IdSearchParameters) {
        LoadByLaundryRoomId(values.id);
    }

    function LoadByLaundryRoomId(searchId: ReactText) {
        if (props.appliance) {
            props.clearView();
        }
        props.loadLocationWithAppliances(String(searchId));
        history.replace({
            pathname: String(Route.MANAGE_APPLIANCE),
            search: `?${laundryRoomIdReqParam}=${searchId}`,
        });
    }

    function LoadByApplianceId(searchId: ReactText) {
        props.loadAppliance(searchId);
        history.replace({
            pathname: String(Route.MANAGE_APPLIANCE),
            search: `?${applianceIdReqParam}=${searchId}`,
        });
    }

    function formatTimeRange(
        startMillis: number | null,
        endMillis: number | null
    ) {
        const start = startMillis ? formatTimestamp(startMillis) : 'unbekannt';
        const end = endMillis ? formatTimestamp(endMillis) : 'unbekannt';
        return start + ' - ' + end;
    }

    function formatTimestamp(epochMillis: number) {
        return moment(epochMillis).format('DD.MM.YYYY, HH:mm');
    }

    function getLaundryRoomInfo(): DisplayedLaundryRoomInfo {
        if (!props.locationWithAppliances) {
            return {};
        }
        const queriedLocationInfo =
            props.locationWithAppliances.location_information;

        return {
            id: queriedLocationInfo.id,
            name: queriedLocationInfo.name,
            city: queriedLocationInfo.address.city,
            street: queriedLocationInfo.address.street,
            houseNumber: queriedLocationInfo.address.house_number,
            postalCode: queriedLocationInfo.address.postal_code,
            activeNote: queriedLocationInfo.active_note,
            note: props.appliance && props.appliance.note,
            criticalNote: props.appliance && props.appliance.critical_note,
            incidents_of_appliances:
                queriedLocationInfo.incidents_of_appliances,
            needs_monitoring: queriedLocationInfo.needs_monitoring,
            visibility_distance: queriedLocationInfo.visibility_distance,
            metadata: queriedLocationInfo.location_meta_data_dto,
            feedback_question_id: queriedLocationInfo.feedback_question_id,
            feedback_question_response_count:
                queriedLocationInfo.feedback_question_response_count,
            desired_feedback_count: queriedLocationInfo.desired_feedback_count,
            pre_payment_required: queriedLocationInfo.pre_payment_required,
            supports_scan2_wash: queriedLocationInfo.supports_scan2_wash,
            not_my_laundry_allowed: queriedLocationInfo.not_my_laundry_allowed,
        };
    }

    function laundryRoomOverview() {
        const appliance: ApplianceDto | null = props.appliance;

        function mapApplianceToDisplay(
            appliance: BasicApplianceInformation
        ): DisplayedApplianceInfo {
            return {
                id: appliance.id,
                active: appliance.enabled,
                name: appliance.short_name,
                incident_type: appliance.incident_type,
            };
        }

        const applianceInfos: Array<DisplayedApplianceInfo> =
            props.locationWithAppliances
                ? props.locationWithAppliances!.appliances.map((appliance) =>
                      mapApplianceToDisplay(appliance)
                  )
                : [];
        const onApplianceSelectedHandler = (
            applianceInfo: DisplayedApplianceInfo
        ) => {
            props.loadAppliance(applianceInfo.id);
        };
        const laundryRoomInfo = getLaundryRoomInfo();
        const onLaundryRoomSelectHandler = (
            laundryRoomInfo: DisplayedLaundryRoomInfo
        ) => {
            props.goTo(
                Route.SEARCH_LAUNDRY_ROOM_BY_ID,
                undefined,
                new Map<string, any>([
                    [laundryRoomIdReqParam, laundryRoomInfo.id],
                ])
            );
        };

        function renderHorizontalApplianceSelect() {
            if (
                props.locationWithAppliances &&
                props.locationWithAppliances.appliances.length === 0
            ) {
                return (
                    <h2>
                        <i>Dieser Waschraum hat keine Maschinen</i>
                    </h2>
                );
            }
            return (
                <HorizontalApplianceSelect
                    applianceInfos={applianceInfos}
                    rowSize={10}
                    cardSize={8}
                    selectedApplianceId={
                        appliance ? appliance.appliance_id : undefined
                    }
                    onApplianceSelectCallback={onApplianceSelectedHandler}
                />
            );
        }

        return (
            <span>
                {searchHeader(
                    'Waschrauminformationen',
                    loadState === LoadState.LaundryRoom
                )}
                <LaundryRoomAnchorCard
                    laundryRoomInfo={laundryRoomInfo}
                    handleCleaningInformationModel={
                        handleCleaningInformationModel
                    }
                    handleNeedsMonitoringOptionEdit={
                        handleNeedsMonitoringOptionEdit
                    }
                    onFeedbackModeEdit={handleFeedbackModeEdit}
                    onVisibilityDistanceEdit={handleVisibilityDistanceEdit}
                    onLaundryRoomSelectCallback={onLaundryRoomSelectHandler}
                    onPrepaymentModeEdit={handlePrepaymentModeEdit}
                    onScan2WashEdit={handleScan2WashEdit}
                    onNotMyLaundryEdit={handleNotMyLaundryEdit}
                    displayLaundryRoomSelectButton={true}
                    onSetLaundryRoomNotes={handleSetLaundryRoomNotes}
                    setLaundryRoomNotesErrors={
                        props.manageLaundryRoomsStore.errors
                    }
                />
                {renderHorizontalApplianceSelect()}
            </span>
        );
    }

    function applianceDataDetails() {
        const appliance: ApplianceDto = props.appliance
            ? props.appliance
            : ({} as ApplianceDto);
        return (
            <div>
                <h5>Zustand</h5>
                <table className={styles.infoTable}>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Aktiv</strong>
                            </td>
                            <td>{String(appliance.is_enabled)}</td>
                        </tr>
                        <tr>
                            <td />
                            <td>
                                seit{' '}
                                {appliance.enabled_activity_time
                                    ? formatTimestamp(
                                          appliance.enabled_activity_time
                                      )
                                    : '?'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Operativer Zustand</strong>
                            </td>
                            <td>{appliance.operational_state}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Zustand und Reservierung</strong>
                            </td>
                            <td>{`${appliance.state} (${
                                appliance.reservation_id || '-'
                            })`}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Konnektivität</strong>
                            </td>
                            <td>{appliance.connectivity_state}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Konnektivität-Warnung</strong>
                            </td>
                            <td>{appliance.connectivity_warning}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Vorfallstyp</strong>
                            </td>
                            <td>
                                {mapIncidentTypeToShortText(
                                    appliance.incident_type
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Kontrollstatus</strong>
                            </td>
                            <td>
                                {appliance.monitoring &&
                                    appliance.monitoring.control_status}
                                {appliance.monitoring &&
                                    renderEditButton(handleControlStatusEdit)}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5>Stammdaten</h5>
                <table className={styles.infoTable}>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>{appliance.short_name}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Box Typ</strong>
                            </td>
                            <td>{appliance.box_type}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Kontroll Typ</strong>
                            </td>
                            <td>{appliance.control_type}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>VIB</strong>
                            </td>
                            <td>{renderModelName()}</td>
                        </tr>
                        <tr>
                            <td>Modellgruppe</td>
                            <td>{appliance.model_group}</td>
                        </tr>
                        <tr>
                            <td>Transformer Typ</td>
                            <td>{appliance.switch_transformer_type}</td>
                        </tr>
                    </tbody>
                </table>
                <h5>Wartungen</h5>
                <table className={styles.infoTable}>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Verifizierungszyklus</strong>
                            </td>
                            <td>
                                {appliance.installation_verification_status ||
                                    '-'}
                            </td>
                        </tr>
                        {appliance.installation_verification_status_update_time && (
                            <tr>
                                <td />
                                <td>{`seit ${formatTimestamp(
                                    appliance.installation_verification_status_update_time
                                )}`}</td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <strong>Blinde Wartung</strong>
                            </td>
                            <td>{appliance.blind_maintenance_status || '-'}</td>
                        </tr>
                        {appliance.blind_maintenance_start_time &&
                            appliance.blind_maintenance_end_time && (
                                <tr>
                                    <td />
                                    <td>
                                        {formatTimeRange(
                                            appliance.blind_maintenance_start_time,
                                            appliance.blind_maintenance_end_time
                                        )}
                                    </td>
                                </tr>
                            )}
                        <tr>
                            <td>
                                <strong>Client Installation</strong>
                            </td>
                            <td>
                                {appliance.client_installation_status || '-'}
                            </td>
                        </tr>
                        {appliance.client_installation_status_update_time && (
                            <tr>
                                <td />
                                <td>{`Seit ${formatTimestamp(
                                    appliance.client_installation_status_update_time
                                )}`}</td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <strong>Switch Firmware</strong>
                            </td>
                            <td>{appliance.switch_firmware || '-'}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Filter Wechsel</strong>
                            </td>
                            <td>{renderFilterReplacementTime()}</td>
                        </tr>
                    </tbody>
                </table>
                <h5>Waschraum</h5>
                <table className={styles.infoTable}>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Waschraum Id</strong>
                            </td>
                            <td>{appliance.location_id}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Aktive Maschinen vom Typ</strong>
                            </td>
                            <td>
                                {String(
                                    appliance.number_of_enabled_appliances_of_type_in_laundry_room
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );

        function renderFilterReplacementTime(): string {
            if (appliance.appliance_type === 'WASHING_MACHINE') {
                return 'n/a';
            }
            if (
                appliance.appliance_times &&
                appliance.appliance_times.lint_filter_replacement
            ) {
                return formatTimestamp(
                    appliance.appliance_times.lint_filter_replacement
                );
            }
            return '-';
        }

        function renderModelName(): string {
            if (appliance.vib) {
                return `${appliance.vib}/${appliance.manufacturing_batch}`;
            }
            return '(unbekannt)';
        }
    }

    function renderEditButton(onClick: () => void) {
        return (
            <Button onClick={onClick} variant={'outline-light'}>
                <FontAwesomeIcon size={'1x'} color="#00B3E6" icon={faEdit} />
            </Button>
        );
    }

    function renderApplianceHistoryList() {
        if (!props.appliance) {
            return null;
        }

        const history = props.appliance.deactivation_history || [];

        return (
            <div>
                <h3>
                    Appliance Deactivation Log (Neuesten {history.length}{' '}
                    Einträge)
                </h3>
                <table className={styles.historyTable}>
                    <tr>
                        <th>Wann</th>
                        <th>Quelle</th>
                        <th>Grund</th>
                        <th>Kommentar</th>
                        <th />
                    </tr>
                    {history.map(renderApplianceHistoryEntry)}
                </table>
            </div>
        );

        function renderApplianceHistoryEntry(
            entry: ApplianceDeactivationEntry
        ) {
            return (
                <tr>
                    <td>{timestampToHrDate(entry.ctime)}</td>
                    <td>{entry.event_source}</td>
                    <td>
                        {translateDeactivationReason(entry.deactivation_reason)}
                    </td>
                    <td>{entry.comment}</td>
                    <td>{entry.target_state}</td>
                </tr>
            );
        }
    }

    function applianceUpdateForm() {
        return (
            <div className={styles.manageApplianceForm}>
                {applianceToggleForm()}
                {installationVerificationForm()}
                {blindMaintenanceForm()}
                {maintenanceScheduleForm()}
                {clientInstallationForm()}
            </div>
        );
    }

    function actionAvailable(action: ActionType): boolean {
        return !!(
            props.appliance &&
            props.appliance.available_actions.includes(action)
        );
    }

    function commentForApplianceMaintenance(
        formProps: FormikProps<any>,
        controlId: string
    ) {
        return (
            <Form.Group controlId={'comment_' + controlId}>
                <Form.Label>Kommentar</Form.Label>
                <Form.Control
                    type="text"
                    name="comment"
                    onChange={formProps.handleChange}
                    value={formProps.values.comment}
                />
            </Form.Group>
        );
    }

    function applianceToggleForm() {
        const buttonText = 'Maschine umschalten';

        function shouldRenderDischargePumpIncidentFlag() {
            return isWashingMachine(props.appliance);
        }

        return (
            <div>
                <h4>Umschaltung</h4>
                <Formik
                    onSubmit={handleToggleApplianceSubmit}
                    initialValues={toggleApplianceParameters}
                >
                    {(formProps: FormikProps<ToggleApplianceParameters>) => {
                        function renderDischargePumpIncidentFlag() {
                            if (shouldRenderDischargePumpIncidentFlag()) {
                                return (
                                    <div hidden={setApplianceToActive}>
                                        <Form.Group controlId="dischargePumpIncident">
                                            <Form.Check
                                                custom
                                                type="checkbox"
                                                label="Umschaltung wegen des Laugenpumpenvorfalls"
                                                checked={
                                                    formProps.values
                                                        .dischargePumpIncident
                                                }
                                                onChange={
                                                    formProps.handleChange
                                                }
                                            />
                                        </Form.Group>
                                    </div>
                                );
                            } else {
                                return <></>;
                            }
                        }

                        const setApplianceToActive = formProps.values.isEnabled;
                        const checkedLintFilterReplacement =
                            formProps.values.isEnabled &&
                            formProps.values.updateFilterReplacementTimestamp &&
                            lintFilterReplacementEnabled;
                        return (
                            <Form onSubmit={formProps.handleSubmit}>
                                <Form.Group controlId="isEnabled">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        label="Aktiv"
                                        checked={formProps.values.isEnabled}
                                        onChange={formProps.handleChange}
                                    />
                                </Form.Group>
                                {commentForApplianceMaintenance(
                                    formProps,
                                    'applianceToggle'
                                )}
                                <div hidden={setApplianceToActive}>
                                    <Form.Group controlId="deactivationReason">
                                        <Form.Label>
                                            Deaktivierungsgrund
                                        </Form.Label>
                                        <Form.Control
                                            as={'select'}
                                            onChange={formProps.handleChange}
                                            value={
                                                formProps.values
                                                    .deactivationReason
                                            }
                                        >
                                            {renderDeactivationReasonOptions()}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                {renderDischargePumpIncidentFlag()}
                                <div hidden={!setApplianceToActive}>
                                    <Form.Group controlId="updateFilterReplacementTimestamp">
                                        <Form.Check
                                            custom
                                            type="checkbox"
                                            label="Filter Austausch hat stattgefunden"
                                            checked={
                                                checkedLintFilterReplacement
                                            }
                                            disabled={
                                                !lintFilterReplacementEnabled
                                            }
                                            onChange={formProps.handleChange}
                                        />
                                        {renderLintFilterReplacementNote(
                                            lintFilterReplacementEnabled
                                        )}
                                    </Form.Group>
                                </div>
                                <Button
                                    type="submit"
                                    disabled={
                                        props.loading ||
                                        !actionAvailable(ActionType.TOGGLE)
                                    }
                                >
                                    {buttonText}
                                </Button>
                                {!actionAvailable(ActionType.TOGGLE) && (
                                    <div>
                                        Umschaltung nicht möglich während eine
                                        Wartungsoperation ausgeführt wird
                                    </div>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }

    function installationVerificationForm() {
        const showStart = actionAvailable(
            ActionType.START_INSTALLATION_VERIFICATION
        );
        const showCancel = actionAvailable(
            ActionType.CANCEL_INSTALLATION_VERIFICATION
        );
        return (
            <details>
                <summary className={styles.actionHeader}>
                    Verifizierungszyklus
                </summary>
                {showStart && startInstallationVerificationForm()}
                {showCancel && cancelInstallationVerificationForm()}
                {!showStart && !showCancel && (
                    <div>
                        Nicht möglich während eine andere Wartungsoperation
                        ausgeführt wird
                    </div>
                )}
            </details>
        );
    }

    function startInstallationVerificationForm() {
        const buttonText = 'Verifizierungszyklus vorbereiten';
        return (
            <Formik
                onSubmit={handleStartVerificationSubmit}
                initialValues={startVerificationParameters}
            >
                {(formProps: FormikProps<StartVerificationParameters>) => (
                    <Form onSubmit={formProps.handleSubmit}>
                        <div
                            className={styles.installationVerificationInfoText}
                        >
                            Die Machine wird sofort für normale Buchungen
                            deaktiviert. Zusätzlich wird sie, sobald möglich,
                            für {String(formProps.values.loadingTimeHours)}{' '}
                            Stunden freigeschaltet, so dass ein
                            Verifizierungszyklus ausgeführt werden kann. Im
                            Erfolgsfall wird sie danach automatisch wieder
                            aktiviert.
                        </div>
                        <Form.Group controlId="sendReportCp">
                            <Form.Check
                                custom
                                type="checkbox"
                                label="Email Benachrichtigung CP"
                                checked={formProps.values.sendReportCp}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sendReportCs">
                            <Form.Check
                                custom
                                type="checkbox"
                                label="Email Benachrichtigung CS"
                                checked={formProps.values.sendReportCs}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="loadingTimeHours">
                            <Form.Label>Beladungszeit (Stunden)</Form.Label>
                            <Form.Control
                                type="number"
                                onChange={formProps.handleChange}
                                value={String(
                                    formProps.values.loadingTimeHours
                                )}
                            />
                        </Form.Group>
                        {commentForApplianceMaintenance(
                            formProps,
                            'installationVerification'
                        )}
                        <Button
                            disabled={props.loading}
                            type="submit"
                            className={styles.actionButton}
                        >
                            {buttonText}
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }

    function cancelInstallationVerificationForm() {
        const buttonText = 'Verifizierungszyklus abbrechen';
        return (
            <Formik
                onSubmit={handleCancelVerificationSubmit}
                initialValues={cancelVerificationParameters}
            >
                {(formProps: FormikProps<CancelVerificationParameters>) => (
                    <Form onSubmit={formProps.handleSubmit}>
                        {commentForApplianceMaintenance(
                            formProps,
                            'cancelVerification'
                        )}
                        <Button
                            disabled={props.loading}
                            type="submit"
                            className={styles.actionButton}
                        >
                            {buttonText}
                        </Button>
                    </Form>
                )}
            </Formik>
        );
    }

    function maintenanceScheduleForm() {
        const showCreate = actionAvailable(
            ActionType.CREATE_MAINTENANCE_SCHEDULE
        );
        return (
            <details>
                <summary className={styles.actionHeader}>
                    Wartungszeitplan
                </summary>
                {maintenanceScheduleTable()}
                {showCreate && (
                    <Button
                        disabled={props.loading}
                        className={styles.addMaintenanceScheduleButton}
                        onClick={handleCreateMaintenanceSchedule}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.icon}
                        />
                    </Button>
                )}
            </details>
        );
    }

    function maintenanceScheduleTable() {
        if (!props.appliance) {
            return null;
        }

        const showUpdate = actionAvailable(
            ActionType.UPDATE_MAINTENANCE_SCHEDULE
        );
        const showDelete = actionAvailable(
            ActionType.DELETE_MAINTENANCE_SCHEDULE
        );
        return (
            <table className={styles.maintenanceScheduleTable}>
                <tbody>
                    {props.appliance.maintenance_schedules.map((it) => {
                        return (
                            <tr>
                                <td>{formatTimestamp(it.next_start)}</td>
                                <td>
                                    {formatDurationAsHours(it.duration) +
                                        ' Std'}
                                </td>
                                <td>
                                    {formatRepeatInterval(
                                        it.repeat_type,
                                        it.repeat_multiplier
                                    )}
                                </td>
                                <td
                                    className={
                                        styles.maintenanceScheduleCommentColumn
                                    }
                                >
                                    {it.comment}
                                </td>
                                <td>
                                    {showUpdate && (
                                        <Button
                                            disabled={props.loading}
                                            variant="outline-secondary"
                                            onClick={() =>
                                                handleUpdateMaintenanceSchedule(
                                                    it
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className={styles.icon}
                                            />
                                        </Button>
                                    )}
                                </td>
                                <td>
                                    {showDelete && (
                                        <Button
                                            disabled={props.loading}
                                            variant="outline-secondary"
                                            onClick={() =>
                                                handleDeleteMaintenanceSchedule(
                                                    it
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className={styles.icon}
                                            />
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    function handleCreateMaintenanceSchedule() {
        if (!providedApplianceId) {
            return;
        }
        props.openModal({
            type: ModalType.UPSERT_MAINTENANCE_SCHEDULE,
            data: {
                applianceId: providedApplianceId,
                showLintFilterSetting: lintFilterReplacementEnabled,
            },
        });
    }

    function handleUpdateMaintenanceSchedule(
        maintenanceSchedule: ApplianceMaintenanceScheduleDto
    ) {
        props.openModal({
            type: ModalType.UPSERT_MAINTENANCE_SCHEDULE,
            data: {
                applianceId: maintenanceSchedule.appliance_id,
                showLintFilterSetting: lintFilterReplacementEnabled,
                oldSchedule: maintenanceSchedule,
            },
        });
    }

    function handleDeleteMaintenanceSchedule(
        maintenanceSchedule: ApplianceMaintenanceScheduleDto
    ) {
        props.deleteMaintenanceSchedule(maintenanceSchedule);
    }

    function blindMaintenanceForm() {
        const showStart = actionAvailable(ActionType.START_BLIND_MAINTENANCE);
        const showCancel = actionAvailable(ActionType.CANCEL_BLIND_MAINTENANCE);
        const showUpdate = actionAvailable(ActionType.UPDATE_BLIND_MAINTENANCE);
        return (
            <details>
                <summary className={styles.actionHeader}>
                    Blinde Wartung
                </summary>
                {showStart && startBlindMaintenanceForm()}
                {showCancel && (
                    <details className={styles.subAction}>
                        <summary className={styles.subActionHeader}>
                            Abbrechen
                        </summary>
                        {cancelBlindMaintenanceForm()}
                    </details>
                )}
                {showUpdate && (
                    <details className={styles.subAction}>
                        <summary className={styles.subActionHeader}>
                            Ändern
                        </summary>
                        {updateBlindMaintenanceForm()}
                    </details>
                )}
                {!showStart && !showCancel && !showUpdate && (
                    <div>
                        Nicht möglich während eine andere Wartungsoperation
                        ausgeführt wird
                    </div>
                )}
            </details>
        );
    }

    function renderLintFilterReplacementNote(
        lintFilterReplacementEnabled?: boolean
    ) {
        if (lintFilterReplacementEnabled) {
            return null;
        } else {
            return (
                <div>
                    <i>Filter Ersatz Einstellungen gelten nur für Trockner.</i>
                </div>
            );
        }
    }

    function startBlindMaintenanceForm() {
        const buttonText = 'Blinde Wartung planen';

        return (
            <Formik
                onSubmit={handleStartBlindMaintenanceSubmit}
                initialValues={startBlindMaintenanceParameters}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<BlindMaintenanceParameters>) => {
                    if (
                        isWashingMachine(props.appliance) &&
                        formProps.values.lintFilterReplacement
                    ) {
                        formProps.values.lintFilterReplacement = undefined;
                    }
                    return (
                        <Form onSubmit={formProps.handleSubmit}>
                            <Form.Group>
                                <Form.Label>Start</Form.Label>
                                <DatePicker
                                    showTimeSelect
                                    dateFormat="dd.MM.yyyy, HH:mm"
                                    selected={formProps.values.startTime}
                                    onChange={(val) =>
                                        val &&
                                        formProps.setFieldValue(
                                            'startTime',
                                            val
                                        )
                                    }
                                />
                                <Button
                                    onClick={() =>
                                        formProps.setFieldValue(
                                            'startTime',
                                            new Date()
                                        )
                                    }
                                    variant={'outline-secondary'}
                                >
                                    Jetzt
                                </Button>
                            </Form.Group>
                            {commentForApplianceMaintenance(
                                formProps,
                                'startBlindMaintenance'
                            )}
                            <Form.Group controlId="reason">
                                <Form.Label>Grund</Form.Label>
                                <Form.Control
                                    as={'select'}
                                    onChange={formProps.handleChange}
                                    value={formProps.values.reason}
                                >
                                    {renderDeactivationReasonOptions()}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="durationHours">
                                <Form.Label>Dauer (Stunden)</Form.Label>
                                <Form.Control
                                    type="number"
                                    onChange={formProps.handleChange}
                                    value={formProps.values.durationHours}
                                />
                            </Form.Group>
                            <Form.Group controlId="targetIsEnabled">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    checked={formProps.values.targetIsEnabled}
                                    label="Machine nach Ende aktivieren"
                                    onChange={formProps.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="lintFilterReplacement">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    checked={
                                        formProps.values.lintFilterReplacement
                                    }
                                    label="Filter Austausch planen"
                                    disabled={!lintFilterReplacementEnabled}
                                    onChange={formProps.handleChange}
                                />
                            </Form.Group>
                            {renderLintFilterReplacementNote()}
                            <Button
                                disabled={props.loading}
                                type="submit"
                                className={styles.actionButton}
                            >
                                {buttonText}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    function updateBlindMaintenanceForm() {
        if (!props.appliance) {
            return null;
        }

        return (
            <Formik
                onSubmit={() => null}
                initialValues={{
                    startTime: new Date(
                        props.appliance.blind_maintenance_start_time || 0
                    ),
                    endTime: new Date(
                        props.appliance.blind_maintenance_end_time || 0
                    ),
                    targetIsEnabled:
                        !!props.appliance.blind_maintenance_target_is_enabled,
                }}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<BlindMaintenanceUpdateParameters>) => {
                    return (
                        <Form onSubmit={formProps.handleSubmit}>
                            <Form.Group>
                                <Form.Label>Start</Form.Label>
                                <DatePicker
                                    showTimeSelect
                                    dateFormat="dd.MM.yyyy, HH:mm"
                                    selected={formProps.values.startTime}
                                    onChange={(val) =>
                                        val &&
                                        formProps.setFieldValue(
                                            'startTime',
                                            val
                                        )
                                    }
                                />
                                <Button
                                    onClick={() =>
                                        handleUpdateBlindMaintenanceSubmit({
                                            startTime:
                                                formProps.values.startTime,
                                        })
                                    }
                                >
                                    Updaten
                                </Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ende</Form.Label>
                                <DatePicker
                                    showTimeSelect
                                    dateFormat="dd.MM.yyyy, HH:mm"
                                    selected={formProps.values.endTime}
                                    onChange={(val) =>
                                        val &&
                                        formProps.setFieldValue('endTime', val)
                                    }
                                />
                                <Button
                                    onClick={() =>
                                        handleUpdateBlindMaintenanceSubmit({
                                            endTime: formProps.values.endTime,
                                        })
                                    }
                                >
                                    Updaten
                                </Button>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    custom
                                    // Needs a different id, otherwise it clashes with the field from the cancel form
                                    // Note: If not explicitly set, the id is copied from Form.Group controlId
                                    id={'update-bm-target-is-enabled'}
                                    type="checkbox"
                                    checked={formProps.values.targetIsEnabled}
                                    label="Machine nach Ende aktivieren"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        formProps.setFieldValue(
                                            'targetIsEnabled',
                                            e.target.checked
                                        )
                                    }
                                />
                                <Button
                                    onClick={() =>
                                        handleUpdateBlindMaintenanceSubmit({
                                            targetIsEnabled:
                                                formProps.values
                                                    .targetIsEnabled,
                                        })
                                    }
                                >
                                    Updaten
                                </Button>
                            </Form.Group>
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    function cancelBlindMaintenanceForm() {
        const buttonText = props.loading ? '...' : 'Blinde Wartung abbrechen';
        const lintFilterReplacement = props.appliance
            ? props.appliance.lint_filter_replacement
            : false;

        function renderLintFilterReplacementRequested() {
            if (lintFilterReplacement) {
                return (
                    <div>
                        <i>Filter Austausch beantragt.</i>
                    </div>
                );
            } else {
                return (
                    <div>
                        <i>Filter Austausch nicht beantragt.</i>
                    </div>
                );
            }
        }

        return (
            <Formik
                onSubmit={handleCancelBlindMaintenanceSubmit}
                initialValues={cancelBlindMaintenanceParameters}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<BlindMaintenanceCancelParameters>) => {
                    if (
                        isWashingMachine(props.appliance) &&
                        formProps.values.lintFilterReplacement
                    ) {
                        formProps.values.lintFilterReplacement = undefined;
                    }
                    return (
                        <Form onSubmit={formProps.handleSubmit}>
                            <Form.Group controlId="targetIsEnabled">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    label="Machine nach Abbruch aktivieren"
                                    checked={formProps.values.targetIsEnabled}
                                    onChange={formProps.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="lintFilterReplacement">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    checked={
                                        formProps.values.lintFilterReplacement
                                    }
                                    label="Filter Austausch hat stattgefunden"
                                    disabled={!lintFilterReplacementEnabled}
                                    onChange={formProps.handleChange}
                                />
                            </Form.Group>
                            {commentForApplianceMaintenance(
                                formProps,
                                'cancelBlindMaintenance'
                            )}
                            {renderLintFilterReplacementRequested()}
                            {renderLintFilterReplacementNote()}
                            <Button
                                disabled={props.loading}
                                type="submit"
                                className={styles.actionButton}
                            >
                                {buttonText}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    function clientInstallationForm() {
        const showRequire = actionAvailable(
            ActionType.REQUIRE_CLIENT_INSTALLATION
        );
        return (
            <details>
                <summary className={styles.actionHeader}>
                    Client Installation
                </summary>
                {showRequire && requireClientInstallationForm()}
                {!showRequire && <div>Nicht möglich im aktuellen Zustand</div>}
            </details>
        );
    }

    function requireClientInstallationForm() {
        const buttonText = 'Client Installation beantragen';
        return (
            <div>
                <div className={styles.installationVerificationInfoText}>
                    Die NetworkId des Clients wird auf den gleichen Wert gesetzt
                    wie die NetworkId des Hubs. Nach kurzer Wartezeit wird dann
                    überprüft, dass der Client die gleiche Switch Firmware
                    Version hat wie der Hub.
                </div>
                <Button
                    disabled={props.loading}
                    className={styles.actionButton}
                    onClick={handleStartClientInstallationSubmit}
                >
                    {buttonText}
                </Button>
            </div>
        );
    }
};

function createSuccessMessage(dto: ApplianceAction) {
    if (!dto) {
        return null;
    }
    switch (dto.actionType) {
        case ActionType.TOGGLE:
            return `Maschine ${dto.applianceId} wurde erfolgreich umgeschaltet.`;
        case ActionType.START_INSTALLATION_VERIFICATION:
            return `Maschine ${dto.applianceId} wird für einen Verifizierungszyklus vorbereitet.`;
        case ActionType.CANCEL_INSTALLATION_VERIFICATION:
            return `Verifizierungszyklus für Maschine ${dto.applianceId} wird abgebrochen.`;
        case ActionType.CREATE_MAINTENANCE_SCHEDULE:
            return `Ein Wartungszeitplan für Machine ${dto.applianceId} wurde erstellt.`;
        case ActionType.UPDATE_MAINTENANCE_SCHEDULE:
            return `Der Wartungszeitplan für Machine ${dto.applianceId} wurde upgedated.`;
        case ActionType.DELETE_MAINTENANCE_SCHEDULE:
            return `Der Wartungszeitplan für Machine ${dto.applianceId} wurde gelöscht.`;
        case ActionType.START_BLIND_MAINTENANCE:
            return `Eine blinde Wartung für Maschine ${dto.applianceId} wurde geplant.`;
        case ActionType.CANCEL_BLIND_MAINTENANCE:
            return `Die blinde Wartung für Maschine ${dto.applianceId} wurde abgebrochen.`;
        case ActionType.UPDATE_BLIND_MAINTENANCE:
            return `Die blinde Wartung für Maschine ${dto.applianceId} wurde upgedated.`;
        case ActionType.REQUIRE_CLIENT_INSTALLATION:
            return `Client Installation für Machine ${dto.applianceId} wurde beantragt.`;
        default:
            return `Die Aktion ${dto.actionType} für Machine ${dto.applianceId} wurde erfolgreich ausgeführt.`;
    }
}

export default connect(
    (store: RootStore) => ({
        ...store.manageAppliance,
        manageLaundryRoomsStore: store.manageLaundryRooms,
    }),
    {
        loadAppliance,
        loadLocationWithAppliances,
        toggleAppliance,
        startInstallationVerification,
        cancelInstallationVerification,
        startBlindMaintenance,
        cancelBlindMaintenance,
        updateBlindMaintenance,
        deleteMaintenanceSchedule,
        startClientInstallation,
        clearView: resetViews,
        goTo,
        openModal,
        loadLaundryRoomNotes,
        setLaundryRoomNotes,
    }
)(ManageApplianceForm);

import React from 'react';
import { closeModal, ModalParameters } from '../../store/modal/modalActions';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import { updateLocationNotMyLaundryAllowed } from '../../store/manage-laundry-room/manageLaundryRoomActions';
import SetLaundryRoomFeatureModal, {
    LaundryRoomFeatureFormData,
} from './SetLaundryRoomFeatureModal';

export interface SetNotMyLaundryParams {
    notMyLaundryAllowed: boolean;
    laundryRoomId: number;
}

interface Props {
    params: SetNotMyLaundryParams;
    close: (params?: ModalParameters<SetNotMyLaundryParams>) => void;
    data: { laundryRoomId?: number; prePaymentRequired?: boolean };
    manageLaundryRoomStore: ManageLaundryRoomStore;
    updateNotMyLaundryAllowed: (
        locationId: number,
        notMyLaundryAllowed: boolean,
        successCallback?: () => void
    ) => void;
}

function SetNotMyLaundryAllowedModal(props: Readonly<Props>) {
    return (
        <SetLaundryRoomFeatureModal
            featureValue={props.params.notMyLaundryAllowed}
            title="Not My Laundry einstellen"
            description={`Soll Not My Laundry für den Waschraum (ID: ${props.data.laundryRoomId}) erlaubt werden?`}
            label="Not My Laundry erlaubt"
            errors={props.manageLaundryRoomStore.errors}
            loading={props.manageLaundryRoomStore.loading}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
        />
    );

    function handleSubmit(value: LaundryRoomFeatureFormData) {
        if (
            props.data &&
            props.data.laundryRoomId &&
            value.featureEnabled !== undefined
        ) {
            props.updateNotMyLaundryAllowed(
                props.data.laundryRoomId,
                value.featureEnabled,
                () => props.close()
            );
        }
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (state: RootStore) => ({
        params: state.modal.modal.data as SetNotMyLaundryParams,
        manageLaundryRoomStore: state.manageLaundryRooms,
    }),
    {
        close: closeModal,
        updateNotMyLaundryAllowed: updateLocationNotMyLaundryAllowed,
    }
)(SetNotMyLaundryAllowedModal);

import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { Formik, FormikProps } from 'formik';
import { Button, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { WeWashApiErrorTag } from '../../http/errors';

export interface LaundryRoomFeatureFormData {
    featureEnabled?: boolean;
}

interface Props {
    featureValue: boolean;
    title: string;
    description: string;
    label: string;
    errors: WeWashApiErrorTag[];
    loading: boolean;
    onCancel: () => void;
    onSubmit: (data: LaundryRoomFeatureFormData) => void;
}

function SetLaundryRoomFeatureModal(props: Readonly<Props>) {
    const initialValues: LaundryRoomFeatureFormData = {
        featureEnabled: props.featureValue,
    };
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Fragment>
    );

    function renderBody() {
        return (
            <Formik onSubmit={props.onSubmit} initialValues={initialValues}>
                {renderForm}
            </Formik>
        );

        function renderForm(
            formProps: FormikProps<LaundryRoomFeatureFormData>
        ) {
            return (
                <Form noValidate onSubmit={formProps.handleSubmit}>
                    <Form.Group
                        as={Row}
                        controlId={'formGroupLaundryRoomId'}
                        className={'mx-0'}
                    >
                        <ErrorFeedback apiErrors={props.errors} />
                        {props.description}
                        <InputGroup>
                            <InputGroup>
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    label={props.label}
                                    name={'featureEnabled'}
                                    id={'featureEnabled'}
                                    onChange={formProps.handleChange}
                                    checked={formProps.values.featureEnabled}
                                />
                            </InputGroup>
                        </InputGroup>
                        <Row className={'mx-0 mt-4'}>
                            <Button
                                onClick={props.onCancel}
                                className={'mr-2'}
                                variant="outline-secondary"
                            >
                                Abbrechen
                            </Button>
                            <Button type={'submit'}>
                                {props.loading && (
                                    <Spinner animation={'grow'} />
                                )}
                                Setzen
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
            );
        }
    }
}

export default SetLaundryRoomFeatureModal;

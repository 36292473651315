import { ReactText } from 'react';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import RestClient from '../../http/RestClient';
import { LaundryLessonFrequency } from '../location/locationReducer';
import { fetchLaundryRoom } from '../location/locationActions';
import { CleaningServiceType } from '../../forms/manage-appliance/LaundryRoomAnchorCard';
import { loadLocationWithAppliances } from '../manage-appliances/manageApplianceActions';

export interface LaundryRoomNotesDto {
    laundry_room_id?: number;
    note: string;
    show_critical_note: boolean;
    critical_note?: string;
}

export interface LaundryRoomConnectivityDto {
    location_id: number;
    connectivity_state?: string;
    connectivity_check_state?: string;
    connectivity_check_update_time?: number;
    client_reconnection_status?: string;
    client_reconnection_status_update_time?: number;
}

export const LOAD_LAUNDRY_ROOM_NOTES = 'LOAD_LAUNDRY_ROOM_NOTES';
export const LOAD_LAUNDRY_ROOM_NOTES_SUCCESS =
    'LOAD_LAUNDRY_ROOM_NOTES_SUCCESS';
export const LOAD_LAUNDRY_ROOM_NOTES_FAILURE =
    'LOAD_LAUNDRY_ROOM_NOTES_FAILURE';

export const SET_LAUNDRY_ROOM_NOTES = 'SET_LAUNDRY_ROOM_NOTES';
export const SET_LAUNDRY_ROOM_NOTES_SUCCESS = 'SET_LAUNDRY_ROOM_NOTES_SUCCESS';
export const SET_LAUNDRY_ROOM_NOTES_FAILURE = 'SET_LAUNDRY_ROOM_NOTES_FAILURE';

export const LOAD_LAUNDRY_ROOM_CARDS = 'LOAD_LAUNDRY_ROOM_CARDS';
export const LOAD_LAUNDRY_ROOM_CARDS_SUCCESS =
    'LOAD_LAUNDRY_ROOM_CARDS_SUCCESS';
export const LOAD_LAUNDRY_ROOM_CARDS_FAILURE =
    'LOAD_LAUNDRY_ROOM_CARDS_FAILURE';

export const CREATE_LAUNDRY_ROOM_CARD = 'CREATE_LAUNDRY_ROOM_CARD';
export const CREATE_LAUNDRY_ROOM_CARD_SUCCESS =
    'CREATE_LAUNDRY_ROOM_CARD_SUCCESS';
export const CREATE_LAUNDRY_ROOM_CARD_FAILURE =
    'CREATE_LAUNDRY_ROOM_CARD_FAILURE';

export const SET_LAUNDRY_ROOM_CARD = 'SET_LAUNDRY_ROOM_CARD';
export const SET_LAUNDRY_ROOM_CARD_SUCCESS = 'SET_LAUNDRY_ROOM_CARD_SUCCESS';
export const SET_LAUNDRY_ROOM_CARD_FAILURE = 'SET_LAUNDRY_ROOM_CARD_FAILURE';

export const SET_LAUNDRY_ROOM_CARD_AS_FIRST = 'SET_LAUNDRY_ROOM_CARD_AS_FIRST';
export const SET_LAUNDRY_ROOM_CARD_AS_FIRST_SUCCESS =
    'SET_LAUNDRY_ROOM_CARD_AS_FIRST_SUCCESS';
export const SET_LAUNDRY_ROOM_CARD_AS_FIRST_FAILURE =
    'SET_LAUNDRY_ROOM_CARD_AS_FIRST_FAILURE';

export const REMOVE_LAUNDRY_ROOM_CARD = 'REMOVE_LAUNDRY_ROOM_CARD';
export const REMOVE_LAUNDRY_ROOM_CARD_SUCCESS =
    'REMOVE_LAUNDRY_ROOM_CARD_SUCCESS';
export const REMOVE_LAUNDRY_ROOM_CARD_FAILURE =
    'REMOVE_LAUNDRY_ROOM_CARD_FAILURE';

export const LOAD_LAUNDRY_ROOM_CONNECTIVITY = 'LOAD_LAUNDRY_ROOM_CONNECTIVITY';
export const LOAD_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS =
    'LOAD_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS';
export const LOAD_LAUNDRY_ROOM_CONNECTIVITY_FAILURE =
    'LOAD_LAUNDRY_ROOM_CONNECTIVITY_FAILURE';

export const CHECK_LAUNDRY_ROOM_CONNECTIVITY =
    'CHECK_LAUNDRY_ROOM_CONNECTIVITY';
export const CHECK_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS =
    'CHECK_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS';
export const CHECK_LAUNDRY_ROOM_CONNECTIVITY_FAILURE =
    'CHECK_LAUNDRY_ROOM_CONNECTIVITY_FAILURE';

export const START_CLIENT_RECONNECTION = 'START_CLIENT_RECONNECTION';
export const START_CLIENT_RECONNECTION_SUCCESS =
    'START_CLIENT_RECONNECTION_SUCCESS';
export const START_CLIENT_RECONNECTION_FAILURE =
    'START_CLIENT_RECONNECTION_FAILURE';

export const UPDATE_LAUNDRY_LESSON_FREQUENCY =
    'UPDATE_LAUNDRY_LESSON_FREQUENCY';
export const UPDATE_LAUNDRY_LESSON_FREQUENCY_SUCCESS =
    'UPDATE_LAUNDRY_LESSON_FREQUENCY_SUCCESS';
export const UPDATE_LAUNDRY_LESSON_FREQUENCY_FAILURE =
    'UPDATE_LAUNDRY_LESSON_FREQUENCY_FAILURE';

export const UPDATE_LAUNDRY_META_INFORMATION =
    'UPDATE_LAUNDRY_META_INFORMATION_FREQUENCY';
export const UPDATE_LAUNDRY_META_INFORMATION_SUCCESS =
    'UPDATE_LAUNDRY_META_INFORMATION_FREQUENCY_SUCCESS';
export const UPDATE_LAUNDRY_META_INFORMATION_FAILURE =
    'UPDATE_LAUNDRY_META_INFORMATION_FREQUENCY_FAILURE';

export const UPDATE_HUB_MONITORING_FLAG_INFORMATION =
    'UPDATE_HUB_MONITORING_FLAG_FREQUENCY';
export const UPDATE_HUB_MONITORING_FLAG_SUCCESS =
    'UPDATE_HUB_MONITORING_FLAG_FREQUENCY_SUCCESS';
export const UPDATE_HUB_MONITORING_FLAG_FAILURE =
    'UPDATE_HUB_MONITORING_FLAG_FREQUENCY_FAILURE';

export const UPDATE_LOCATION_VISIBILITY_DISTANCE =
    'UPDATE_LOCATION_VISIBILITY_DISTANCE';
export const UPDATE_LOCATION_VISIBILITY_DISTANCE_SUCCESS =
    'UPDATE_LOCATION_VISIBILITY_DISTANCE_SUCCESS';
export const UPDATE_LOCATION_VISIBILITY_DISTANCE_FAILURE =
    'UPDATE_LOCATION_VISIBILITY_DISTANCE_FAILURE';

export const UPDATE_LOCATION_PRE_PAYMENT = 'UPDATE_LOCATION_PRE_PAYMENT';
export const UPDATE_LOCATION_PRE_PAYMENT_SUCCESS =
    'UPDATE_LOCATION_PRE_PAYMENT_SUCCESS';

export const UPDATE_LOCATION_PRE_PAYMENT_FAILURE =
    'UPDATE_LOCATION_PRE_PAYMENT_FAILURE';

export const UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH =
    'UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH';
export const UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_SUCCESS =
    'UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_SUCCESS';

export const UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_FAILURE =
    'UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_FAILURE';

export const UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY =
    'UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY';
export const UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_SUCCESS =
    'UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_SUCCESS';

export const UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_FAILURE =
    'UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_FAILURE';

export function loadLaundryRoomNotes(locationId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_LAUNDRY_ROOM_NOTES });
        try {
            const { data } = await RestClient.get<LaundryRoomNotesDto>(
                `/v1/callcenter/laundry_room_notes/${locationId}`
            );
            dispatch({
                type: LOAD_LAUNDRY_ROOM_NOTES_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: LOAD_LAUNDRY_ROOM_NOTES_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function setLaundryRoomNotes(
    locationId: ReactText,
    notes: LaundryRoomNotesDto,
    onDone?: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SET_LAUNDRY_ROOM_NOTES });
        try {
            const { data } = await RestClient.post<LaundryRoomNotesDto>(
                `/v1/callcenter/laundry_room_notes/${locationId}`,
                notes
            );
            dispatch({
                type: SET_LAUNDRY_ROOM_NOTES_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: SET_LAUNDRY_ROOM_NOTES_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            onDone && onDone();
        }
    };
}

export interface LaundryRoomCardWriteDto {
    laundry_room_id: number;
    title: { [key: string]: string };
    description: { [key: string]: string };
    button_text: { [key: string]: string };
    link: { [key: string]: string };
    internal_link: boolean;
}

export interface LaundryRoomCardDto extends LaundryRoomCardWriteDto {
    card_id: number;
}

export interface LaundryRoomCardsDto {
    cards: LaundryRoomCardDto[];
    languages: string[];
    default_language: string;
}

export function loadLaundryRoomCards(locationId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_LAUNDRY_ROOM_CARDS });
        try {
            const { data } = await RestClient.get<LaundryRoomCardsDto>(
                `/v1/callcenter/laundry_room_cards/${locationId}`
            );
            dispatch({
                type: LOAD_LAUNDRY_ROOM_CARDS_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: LOAD_LAUNDRY_ROOM_CARDS_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function createLaundryRoomCard(
    locationId: number,
    writeDto: LaundryRoomCardWriteDto
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CREATE_LAUNDRY_ROOM_CARD });
        try {
            const { data } = await RestClient.post<LaundryRoomCardWriteDto>(
                `/v1/callcenter/laundry_room_cards/${locationId}`,
                writeDto
            );
            dispatch({
                type: CREATE_LAUNDRY_ROOM_CARD_SUCCESS,
                payload: { data },
            });
            await dispatch(loadLaundryRoomCards(locationId));
        } catch (e) {
            dispatch({
                type: CREATE_LAUNDRY_ROOM_CARD_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function setLaundryRoomCard(
    locationId: number,
    cardId: number,
    writeDto: LaundryRoomCardWriteDto
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SET_LAUNDRY_ROOM_CARD });
        try {
            const { data } = await RestClient.patch<LaundryRoomCardWriteDto>(
                `/v1/callcenter/laundry_room_cards/${locationId}/${cardId}`,
                writeDto
            );
            dispatch({
                type: SET_LAUNDRY_ROOM_CARD_SUCCESS,
                payload: { data },
            });
            await dispatch(loadLaundryRoomCards(locationId));
        } catch (e) {
            dispatch({
                type: SET_LAUNDRY_ROOM_CARD_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function setCardAsFirst(locationId: number, cardId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SET_LAUNDRY_ROOM_CARD_AS_FIRST });
        try {
            await RestClient.post(
                `/v1/callcenter/laundry_room_cards/${locationId}/set_first/${cardId}`
            );
            dispatch({ type: SET_LAUNDRY_ROOM_CARD_AS_FIRST_SUCCESS });
            await dispatch(loadLaundryRoomCards(locationId));
        } catch (e) {
            dispatch({
                type: SET_LAUNDRY_ROOM_CARD_AS_FIRST_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function unsetLaundryRoomCard(locationId: number, cardId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REMOVE_LAUNDRY_ROOM_CARD });
        try {
            const { data } = await RestClient.delete(
                `/v1/callcenter/laundry_room_cards/${locationId}/${cardId}`
            );
            dispatch({
                type: REMOVE_LAUNDRY_ROOM_CARD_SUCCESS,
                payload: { data },
            });
            await dispatch(loadLaundryRoomCards(locationId));
        } catch (e) {
            dispatch({
                type: REMOVE_LAUNDRY_ROOM_CARD_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function loadLaundryRoomConnectivity(locationId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_LAUNDRY_ROOM_CONNECTIVITY });
        try {
            const { data } = await RestClient.get<LaundryRoomConnectivityDto>(
                `/v1/callcenter/locations/${locationId}/connectivity`
            );
            dispatch({
                type: LOAD_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: LOAD_LAUNDRY_ROOM_CONNECTIVITY_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function checkLaundryRoomConnectivity(locationId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CHECK_LAUNDRY_ROOM_CONNECTIVITY });
        try {
            await RestClient.post(
                `/v1/callcenter/locations/${locationId}/connectivity_check`
            );
            dispatch({ type: CHECK_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS });

            await dispatch(loadLaundryRoomConnectivity(locationId));
        } catch (e) {
            dispatch({
                type: CHECK_LAUNDRY_ROOM_CONNECTIVITY_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function startClientReconnection(locationId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: START_CLIENT_RECONNECTION });
        try {
            await RestClient.post(
                `/v1/callcenter/locations/${locationId}/client_reconnection`
            );
            dispatch({ type: START_CLIENT_RECONNECTION_SUCCESS });

            await dispatch(loadLaundryRoomConnectivity(locationId));
        } catch (e) {
            dispatch({
                type: START_CLIENT_RECONNECTION_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function updateLaundryLessonFrequencyInLocation(
    locationId: ReactText,
    frequency: LaundryLessonFrequency
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_LAUNDRY_LESSON_FREQUENCY });
        try {
            const sentData = { frequency };
            await RestClient.post(
                `/v1/callcenter/locations/${locationId}/update_lesson_frequency`,
                sentData
            );
            dispatch({ type: UPDATE_LAUNDRY_LESSON_FREQUENCY_SUCCESS });
        } catch (e) {
            dispatch({
                type: UPDATE_LAUNDRY_LESSON_FREQUENCY_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            dispatch(fetchLaundryRoom(locationId));
        }
    };
}

export function updateLocationMetadata(
    locationId: number,
    cleaningService: CleaningServiceType
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_LAUNDRY_META_INFORMATION });
        const request = {
            cleaning_service: cleaningService,
        };
        try {
            const { data } = await RestClient.put(
                `/v1/callcenter/locations/${locationId}`,
                request
            );
            dispatch({
                type: UPDATE_LAUNDRY_META_INFORMATION_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: UPDATE_LAUNDRY_META_INFORMATION_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            await dispatch(fetchLaundryRoom(locationId));
            await dispatch(loadLocationWithAppliances(String(locationId)));
        }
    };
}

export function updateHubMonitoringFlag(
    locationId: number,
    needs_monitoring: boolean
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_HUB_MONITORING_FLAG_INFORMATION });
        const request = {
            needs_monitoring: needs_monitoring,
        };
        try {
            const { data } = await RestClient.put(
                `/v1/callcenter/router-config/location/${locationId}`,
                request
            );
            dispatch({
                type: UPDATE_HUB_MONITORING_FLAG_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: UPDATE_HUB_MONITORING_FLAG_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            dispatch(fetchLaundryRoom(locationId));
            await dispatch(loadLocationWithAppliances(String(locationId)));
        }
    };
}

export function updateLocationVisibilityDistance(
    locationId: number,
    distanceInMeters: number
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_LOCATION_VISIBILITY_DISTANCE });
        const request = {
            visibility_distance: distanceInMeters,
        };
        try {
            const { data } = await RestClient.put(
                `/v1/callcenter/locations/${locationId}/set_visibility_distance`,
                request
            );
            dispatch({
                type: UPDATE_LOCATION_VISIBILITY_DISTANCE_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: UPDATE_LOCATION_VISIBILITY_DISTANCE_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            await dispatch(fetchLaundryRoom(locationId));
            await dispatch(loadLocationWithAppliances(String(locationId)));
        }
    };
}

export function updatePrePaymentForLocation(
    locationId: number,
    requirePrePayment: boolean,
    successCallBack?: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_LOCATION_PRE_PAYMENT });
        const request = {
            pre_payment_required: requirePrePayment,
        };
        try {
            const { data } = await RestClient.patch(
                `/v1/callcenter/laundry_room/${locationId}/pre-payment`,
                request
            );
            dispatch({
                type: UPDATE_LOCATION_PRE_PAYMENT_SUCCESS,
                payload: { data },
            });
            if (successCallBack) {
                successCallBack();
            }
        } catch (e) {
            dispatch({
                type: UPDATE_LOCATION_PRE_PAYMENT_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            await dispatch(fetchLaundryRoom(locationId));
            await dispatch(loadLocationWithAppliances(String(locationId)));
        }
    };
}

export function updateLocationSetSupportsScan2Wash(
    locationId: number,
    supportsScan2Wash: boolean,
    successCallback?: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH });
        const request = {
            scan_2_wash: supportsScan2Wash,
        };
        try {
            const { data } = await RestClient.patch(
                `/v1/callcenter/laundry_room/${locationId}/scan2wash`,
                request
            );
            dispatch({
                type: UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_SUCCESS,
                payload: { data },
            });
            if (successCallback) {
                successCallback();
            }
        } catch (e) {
            dispatch({
                type: UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            await dispatch(fetchLaundryRoom(locationId));
            await dispatch(loadLocationWithAppliances(String(locationId)));
        }
    };
}

export function updateLocationNotMyLaundryAllowed(
    locationId: number,
    notMyLaundryAllowed: boolean,
    successCallback?: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY });
        const request = {
            not_my_laundry_allowed: notMyLaundryAllowed,
        };
        try {
            const { data } = await RestClient.patch(
                `/v1/callcenter/laundry_room/${locationId}/nml`,
                request
            );
            dispatch({
                type: UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_SUCCESS,
                payload: { data },
            });
            if (successCallback) {
                successCallback();
            }
        } catch (e) {
            dispatch({
                type: UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        } finally {
            await dispatch(fetchLaundryRoom(locationId));
            await dispatch(loadLocationWithAppliances(String(locationId)));
        }
    };
}

import React from 'react';
import { closeModal, ModalParameters } from '../../store/modal/modalActions';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import { updateLocationSetSupportsScan2Wash } from '../../store/manage-laundry-room/manageLaundryRoomActions';
import SetLaundryRoomFeatureModal, {
    LaundryRoomFeatureFormData,
} from './SetLaundryRoomFeatureModal';

export interface SetSupportsScan2WashParams {
    supportsScan2Wash: boolean;
    laundryRoomId: number;
}

interface Props {
    params: SetSupportsScan2WashParams;
    close: (params?: ModalParameters<SetSupportsScan2WashParams>) => void;
    data: { laundryRoomId?: number; prePaymentRequired?: boolean };
    manageLaundryRoomStore: ManageLaundryRoomStore;
    updateSupportsScan2Wash: (
        locationId: number,
        supportScan2Wash: boolean,
        successCallback?: () => void
    ) => void;
}

function SetSupportsScan2WashForLaundryRoomModal(props: Readonly<Props>) {
    return (
        <SetLaundryRoomFeatureModal
            featureValue={props.params.supportsScan2Wash}
            title="Scan2Wash Einstellen"
            description={`Soll Scan2Wash für den Waschraum (ID: ${props.data.laundryRoomId}) aktiviert werden?`}
            label="Scan2Wash erlaubt"
            errors={props.manageLaundryRoomStore.errors}
            loading={props.manageLaundryRoomStore.loading}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
        />
    );

    function handleSubmit(value: LaundryRoomFeatureFormData) {
        if (
            props.data &&
            props.data.laundryRoomId &&
            value.featureEnabled !== undefined
        ) {
            props.updateSupportsScan2Wash(
                props.data.laundryRoomId,
                value.featureEnabled,
                () => props.close()
            );
        }
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (state: RootStore) => ({
        params: state.modal.modal.data as SetSupportsScan2WashParams,
        manageLaundryRoomStore: state.manageLaundryRooms,
    }),
    {
        close: closeModal,
        updateSupportsScan2Wash: updateLocationSetSupportsScan2Wash,
    }
)(SetSupportsScan2WashForLaundryRoomModal);

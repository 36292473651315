import React from 'react';
import styles from '../manage-appliance/ManageApplianceForm.module.css';
import Button from 'react-bootstrap/Button';
import {
    hasDischargePumpIncident,
    LaundryRoomWithApplianceStates,
} from '../../store/manage-laundry-room/manageLaundryRoomUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { LifecycleStatus } from '../../store/location/locationReducer';
import { translateLifecycleStatus } from '../../components/user-information-table/UserInformationTable';
import MultilineInlineEdit from './MultilineInlineEdit';
import { LaundryRoomNotesDto } from '../../store/manage-laundry-room/manageLaundryRoomActions';
import { MAX_LENGTH_ALLOWED_FOR_NOTE } from '../manage-laundry-room/ManageLaundryRoomNotesForm';
import { WeWashApiErrorTag } from '../../http/errors';
import ErrorFeedback from '../ErrorFeedback';

export interface DisplayedLaundryRoomInfo
    extends LaundryRoomWithApplianceStates {
    id?: number;
    name?: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
    city?: string;
    activeNote?: string;
    note?: string | null;
    criticalNote?: string | null;
    metadata?: LocationMetadata;
    needs_monitoring?: boolean | false;
    visibility_distance?: number;
    lifecycleStatus?: LifecycleStatus;
    feedback_question_id?: number;
    feedback_question_response_count?: number;
    desired_feedback_count?: number;
    pre_payment_required?: boolean;
    supports_scan2_wash?: boolean;
    not_my_laundry_allowed?: boolean;
}

interface LaundryRoomAnchorCardProperties {
    laundryRoomInfo?: DisplayedLaundryRoomInfo;
    displayLaundryRoomSelectButton?: boolean;
    criticalNoteActive?: boolean;
    onLaundryRoomSelectCallback?: (
        laundryRoomInfo: DisplayedLaundryRoomInfo
    ) => void;
    maxWidth?: string;
    handleCleaningInformationModel: () => void;
    handleNeedsMonitoringOptionEdit: () => void;
    onVisibilityDistanceEdit: () => void;
    onFeedbackModeEdit: () => void;
    onPrepaymentModeEdit?: () => void;
    onScan2WashEdit?: () => void;
    onNotMyLaundryEdit?: () => void;
    onSetLaundryRoomNotes: (notes: LaundryRoomNotesDto) => void;
    setLaundryRoomNotesErrors?: WeWashApiErrorTag[];
}

export interface LocationMetadata {
    cleaning_service: CleaningServiceType;
}

export enum CleaningServiceType {
    NONE = 'NONE',
    WEWASH_APPLIANCES = 'WEWASH_APPLIANCES',
    WEWASH_APPLIANCES_AND_ROOM = 'WEWASH_APPLIANCES_AND_ROOM',
}

export const CLEANING_SERVICE_TRANSLATIONS: {
    [key in CleaningServiceType]: string;
} = {
    [CleaningServiceType.NONE]: 'Partner',
    [CleaningServiceType.WEWASH_APPLIANCES]: 'Maschinen',
    [CleaningServiceType.WEWASH_APPLIANCES_AND_ROOM]: 'Maschinen und Raum',
};

interface RenderNoteProps {
    noteName: string;
    note?: string | null;
    activeNote?: boolean;
    onSetNote: (value: string) => void;
}

function LaundryRoomAnchorCard(props: LaundryRoomAnchorCardProperties) {
    const laundryRoomInfo: DisplayedLaundryRoomInfo = props.laundryRoomInfo
        ? props.laundryRoomInfo
        : {};
    const onLaundryRoomSelectCallback = (event: any) => {
        if (laundryRoomInfo && props.onLaundryRoomSelectCallback) {
            props.onLaundryRoomSelectCallback(laundryRoomInfo);
        }
    };

    function renderActiveNote(defProps: RenderNoteProps) {
        const noteStyle = {
            wordWrap: 'break-word' as const,
            maxWidth: props.maxWidth ? props.maxWidth : 'inherit',
        };
        if (defProps.note && defProps.activeNote) {
            return (
                <tr>
                    <td>
                        <FontAwesomeIcon icon={faCheckCircle} />{' '}
                        <strong>
                            {defProps.noteName} <i>(Aktiv)</i>
                        </strong>
                    </td>
                    <td style={noteStyle}>{renderNoteText()}</td>
                </tr>
            );
        } else {
            return (
                <tr>
                    <td>
                        <strong>{defProps.noteName}</strong>
                    </td>
                    <td>{renderNoteText()}</td>
                </tr>
            );
        }

        function renderNoteText() {
            return (
                <MultilineInlineEdit
                    value={defProps.note || ''}
                    onSetValue={defProps.onSetNote}
                    validate={validateNoteText}
                    errorText={`Text zu lang - maximal ${MAX_LENGTH_ALLOWED_FOR_NOTE} Zeichen`}
                />
            );
        }
    }

    const style = props.maxWidth
        ? {
              maxWidth: props.maxWidth,
              wordWrap: 'break-word' as const,
          }
        : {};

    function displayLaundryRoomSelectButton() {
        if (props.displayLaundryRoomSelectButton) {
            return (
                <tr>
                    <td>
                        <Button
                            type="submit"
                            onClick={onLaundryRoomSelectCallback}
                        >
                            Zur Waschraumübersicht
                        </Button>
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    }

    function handleCleaningInformationEdit() {
        props.handleCleaningInformationModel();
    }

    function handleNeedsMonitoringOptionEdit() {
        props.handleNeedsMonitoringOptionEdit();
    }

    const criticalNoteIsActive =
        props.criticalNoteActive ||
        laundryRoomInfo.activeNote === laundryRoomInfo.criticalNote;
    return (
        <table className={styles.infoTable} style={style}>
            <colgroup>
                <col style={{ width: '30%' }} />
                <col style={{ width: '70%' }} />
            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <strong>ID</strong>
                    </td>
                    <td>{laundryRoomInfo.id}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>{laundryRoomInfo.name}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Straße</strong>
                    </td>
                    <td>
                        {laundryRoomInfo.street} {laundryRoomInfo.houseNumber}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Stadt</strong>
                    </td>
                    <td>{laundryRoomInfo.city}</td>
                </tr>
                <tr>
                    <td>
                        <strong>PLZ</strong>
                    </td>
                    <td>{laundryRoomInfo.postalCode}</td>
                </tr>
                <tr>
                    <td>
                        <strong>Laugenpumpenvorfall ist aufgetreten</strong>
                    </td>
                    <td>
                        {hasDischargePumpIncident(laundryRoomInfo)
                            ? 'Ja'
                            : 'Nein'}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Lebenszyklusstatus</strong>
                    </td>
                    <td>
                        {translateLifecycleStatus(
                            laundryRoomInfo.lifecycleStatus
                        )}
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Reinigungsinformationen</strong>
                    </td>
                    <td>
                        {getCleaningServiceTranslations(
                            laundryRoomInfo.metadata &&
                                laundryRoomInfo.metadata.cleaning_service
                        )}
                        <Button
                            onClick={handleCleaningInformationEdit}
                            variant={'outline-light'}
                        >
                            <FontAwesomeIcon
                                size={'1x'}
                                color="#00B3E6"
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Hub-Monitoring</strong>
                    </td>
                    <td>
                        {laundryRoomInfo.needs_monitoring ? 'Ja' : 'Nein'}

                        <Button
                            onClick={handleNeedsMonitoringOptionEdit}
                            variant={'outline-light'}
                        >
                            <FontAwesomeIcon
                                size={'1x'}
                                color="#00B3E6"
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
                {laundryRoomInfo.visibility_distance != null && (
                    <tr>
                        <td>
                            <strong>
                                Sichtbarkeit für Waschraumzuweisung [m]
                            </strong>
                        </td>
                        <td>
                            {laundryRoomInfo.visibility_distance}
                            <Button
                                onClick={props.onVisibilityDistanceEdit}
                                variant={'outline-light'}
                            >
                                <FontAwesomeIcon
                                    size={'1x'}
                                    color="#00B3E6"
                                    icon={faEdit}
                                />
                            </Button>
                        </td>
                    </tr>
                )}
                <tr>
                    <td>
                        <strong>Feedback Mode</strong>
                    </td>
                    <td>
                        {getFeedbackMode()}
                        <Button
                            onClick={props.onFeedbackModeEdit}
                            variant={'outline-light'}
                        >
                            <FontAwesomeIcon
                                size={'1x'}
                                color="#00B3E6"
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>
                            Pre-Payment bei Checkout-Zahlung angefordert
                        </strong>
                    </td>
                    <td>
                        {getPrepaymentAllowed()}
                        <Button
                            onClick={props.onPrepaymentModeEdit}
                            variant={'outline-light'}
                        >
                            <FontAwesomeIcon
                                size={'1x'}
                                color="#00B3E6"
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>Scan2Wash</strong>
                    </td>
                    <td>
                        {laundryRoomInfo.supports_scan2_wash
                            ? 'Aktiv'
                            : 'Nicht aktiv'}
                        <Button
                            onClick={props.onScan2WashEdit}
                            variant={'outline-light'}
                        >
                            <FontAwesomeIcon
                                size={'1x'}
                                color="#00B3E6"
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <strong>NML Erlaubt</strong>
                    </td>
                    <td>
                        {laundryRoomInfo.not_my_laundry_allowed ? 'Ja' : 'Nein'}
                        <Button
                            onClick={props.onNotMyLaundryEdit}
                            variant={'outline-light'}
                        >
                            <FontAwesomeIcon
                                size={'1x'}
                                color="#00B3E6"
                                icon={faEdit}
                            />
                        </Button>
                    </td>
                </tr>
                {laundryRoomInfo.feedback_question_id && (
                    <tr>
                        <td>Anzahl der Antworten</td>
                        <td>
                            {`${laundryRoomInfo.feedback_question_response_count} / ${laundryRoomInfo.desired_feedback_count}`}
                        </td>
                    </tr>
                )}
                <tr>
                    <td colSpan={2} className={styles.separator} />
                </tr>
                {renderActiveNote({
                    noteName: 'Hinweis',
                    activeNote: !criticalNoteIsActive,
                    note: laundryRoomInfo.note,
                    onSetNote: createNoteHandler(false),
                })}
                <tr>
                    <td colSpan={2} className={styles.separator} />
                </tr>
                {renderActiveNote({
                    noteName: 'Kritischer Hinweis',
                    activeNote: criticalNoteIsActive,
                    note: laundryRoomInfo.criticalNote,
                    onSetNote: createNoteHandler(true),
                })}
                <tr>
                    <td colSpan={2} className={styles.separator} />
                </tr>
                <tr>
                    <td colSpan={2}>
                        <ErrorFeedback
                            apiErrors={props.setLaundryRoomNotesErrors}
                        />
                    </td>
                </tr>

                {displayLaundryRoomSelectButton()}
            </tbody>
        </table>
    );

    function getFeedbackMode() {
        if (laundryRoomInfo.feedback_question_id) {
            return `ON`;
        } else {
            return 'OFF';
        }
    }

    function getPrepaymentAllowed() {
        if (laundryRoomInfo.pre_payment_required) {
            return 'Ja';
        } else {
            return 'Nein';
        }
    }

    function createNoteHandler(criticalNote: boolean): (value: string) => void {
        if (criticalNote) {
            return (value: string) => {
                const showCriticialNote = !!value;
                props.onSetLaundryRoomNotes({
                    laundry_room_id: laundryRoomInfo.id,
                    note: laundryRoomInfo.note || '',
                    critical_note: value,
                    show_critical_note: showCriticialNote,
                });
            };
        } else {
            return (value: string) => {
                props.onSetLaundryRoomNotes({
                    laundry_room_id: laundryRoomInfo.id,
                    note: value,
                    critical_note: laundryRoomInfo.criticalNote || '',
                    show_critical_note: criticalNoteIsActive,
                });
            };
        }
    }
}

function validateNoteText(value: string) {
    if (!value) {
        return true;
    }
    return value.length <= MAX_LENGTH_ALLOWED_FOR_NOTE;
}

export function getCleaningServiceTranslations(cleaning?: CleaningServiceType) {
    if (!cleaning) {
        return 'n/a';
    }
    return CLEANING_SERVICE_TRANSLATIONS[cleaning] || cleaning;
}

export default LaundryRoomAnchorCard;

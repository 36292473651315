import {
    CHECK_LAUNDRY_ROOM_CONNECTIVITY,
    CHECK_LAUNDRY_ROOM_CONNECTIVITY_FAILURE,
    CHECK_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS,
    LaundryRoomCardsDto,
    LaundryRoomConnectivityDto,
    LaundryRoomNotesDto,
    LOAD_LAUNDRY_ROOM_CARDS,
    LOAD_LAUNDRY_ROOM_CARDS_FAILURE,
    LOAD_LAUNDRY_ROOM_CARDS_SUCCESS,
    LOAD_LAUNDRY_ROOM_CONNECTIVITY,
    LOAD_LAUNDRY_ROOM_CONNECTIVITY_FAILURE,
    LOAD_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS,
    LOAD_LAUNDRY_ROOM_NOTES,
    LOAD_LAUNDRY_ROOM_NOTES_FAILURE,
    LOAD_LAUNDRY_ROOM_NOTES_SUCCESS,
    REMOVE_LAUNDRY_ROOM_CARD,
    REMOVE_LAUNDRY_ROOM_CARD_FAILURE,
    REMOVE_LAUNDRY_ROOM_CARD_SUCCESS,
    SET_LAUNDRY_ROOM_CARD,
    SET_LAUNDRY_ROOM_CARD_AS_FIRST,
    SET_LAUNDRY_ROOM_CARD_AS_FIRST_FAILURE,
    SET_LAUNDRY_ROOM_CARD_AS_FIRST_SUCCESS,
    SET_LAUNDRY_ROOM_CARD_FAILURE,
    SET_LAUNDRY_ROOM_CARD_SUCCESS,
    SET_LAUNDRY_ROOM_NOTES,
    SET_LAUNDRY_ROOM_NOTES_FAILURE,
    SET_LAUNDRY_ROOM_NOTES_SUCCESS,
    START_CLIENT_RECONNECTION,
    START_CLIENT_RECONNECTION_FAILURE,
    START_CLIENT_RECONNECTION_SUCCESS,
    UPDATE_HUB_MONITORING_FLAG_FAILURE,
    UPDATE_HUB_MONITORING_FLAG_INFORMATION,
    UPDATE_HUB_MONITORING_FLAG_SUCCESS,
    UPDATE_LAUNDRY_META_INFORMATION,
    UPDATE_LAUNDRY_META_INFORMATION_FAILURE,
    UPDATE_LAUNDRY_META_INFORMATION_SUCCESS,
    UPDATE_LOCATION_PRE_PAYMENT,
    UPDATE_LOCATION_PRE_PAYMENT_FAILURE,
    UPDATE_LOCATION_PRE_PAYMENT_SUCCESS,
    UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY,
    UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_FAILURE,
    UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_SUCCESS,
    UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH,
    UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_FAILURE,
    UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_SUCCESS,
    UPDATE_LOCATION_VISIBILITY_DISTANCE,
    UPDATE_LOCATION_VISIBILITY_DISTANCE_FAILURE,
    UPDATE_LOCATION_VISIBILITY_DISTANCE_SUCCESS,
} from './manageLaundryRoomActions';
import { WeWashApiErrorTag } from '../../http/errors';
import { createReducer } from 'redux-starter-kit';
import { RESET_VIEWS } from '../genericActions';

export interface ManageLaundryRoomStore {
    loading: boolean;
    laundryRoomNotes: LaundryRoomNotesDto | null;
    errors: WeWashApiErrorTag[];
    updatedLaundryRoomNotes: LaundryRoomNotesDto | null;
    laundryRoomCards: LaundryRoomCardsDto | null;
    laundryRoomConnectivity: LaundryRoomConnectivityDto | null;
}

const initialStore: ManageLaundryRoomStore = {
    loading: false,
    laundryRoomNotes: null,
    errors: [],
    updatedLaundryRoomNotes: null,
    laundryRoomCards: null,
    laundryRoomConnectivity: null,
};

export const manageLaundryRoomReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_LAUNDRY_ROOM_NOTES]: (state: ManageLaundryRoomStore) => {
        state.loading = true;
        state.errors = [];
        state.updatedLaundryRoomNotes = null;
    },
    [LOAD_LAUNDRY_ROOM_NOTES_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.laundryRoomNotes = action.payload.data;
    },
    [LOAD_LAUNDRY_ROOM_NOTES_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.laundryRoomNotes = null;
    },
    [SET_LAUNDRY_ROOM_NOTES]: (state: ManageLaundryRoomStore) => {
        state.loading = true;
        state.errors = [];
        state.updatedLaundryRoomNotes = null;
    },
    [SET_LAUNDRY_ROOM_NOTES_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.laundryRoomNotes = action.payload.data;
        state.updatedLaundryRoomNotes = action.payload.data;
    },
    [SET_LAUNDRY_ROOM_NOTES_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.laundryRoomNotes = null;
    },
    [LOAD_LAUNDRY_ROOM_CARDS]: (state: ManageLaundryRoomStore, action) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_LAUNDRY_ROOM_CARDS_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.laundryRoomCards = action.payload.data;
    },
    [LOAD_LAUNDRY_ROOM_CARDS_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.laundryRoomCards = null;
    },
    [SET_LAUNDRY_ROOM_CARD]: (state: ManageLaundryRoomStore, action) => {
        state.loading = true;
        state.errors = [];
    },
    [SET_LAUNDRY_ROOM_CARD_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [SET_LAUNDRY_ROOM_CARD_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [SET_LAUNDRY_ROOM_CARD_AS_FIRST]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = true;
        state.errors = [];
    },
    [SET_LAUNDRY_ROOM_CARD_AS_FIRST_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [SET_LAUNDRY_ROOM_CARD_AS_FIRST_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [REMOVE_LAUNDRY_ROOM_CARD]: (state: ManageLaundryRoomStore, action) => {
        state.loading = true;
        state.errors = [];
    },
    [REMOVE_LAUNDRY_ROOM_CARD_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [REMOVE_LAUNDRY_ROOM_CARD_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [LOAD_LAUNDRY_ROOM_CONNECTIVITY]: (state: ManageLaundryRoomStore) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.laundryRoomConnectivity = action.payload.data;
    },
    [LOAD_LAUNDRY_ROOM_CONNECTIVITY_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [CHECK_LAUNDRY_ROOM_CONNECTIVITY]: (state: ManageLaundryRoomStore) => {
        state.loading = true;
        state.errors = [];
    },
    [CHECK_LAUNDRY_ROOM_CONNECTIVITY_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [CHECK_LAUNDRY_ROOM_CONNECTIVITY_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [START_CLIENT_RECONNECTION]: (state: ManageLaundryRoomStore) => {
        state.loading = true;
        state.errors = [];
    },
    [START_CLIENT_RECONNECTION_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [START_CLIENT_RECONNECTION_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LAUNDRY_META_INFORMATION]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = true;
    },
    [UPDATE_LAUNDRY_META_INFORMATION_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [UPDATE_LAUNDRY_META_INFORMATION_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_HUB_MONITORING_FLAG_INFORMATION]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = true;
    },
    [UPDATE_HUB_MONITORING_FLAG_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [UPDATE_HUB_MONITORING_FLAG_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_VISIBILITY_DISTANCE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = true;
    },
    [UPDATE_LOCATION_VISIBILITY_DISTANCE_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [UPDATE_LOCATION_VISIBILITY_DISTANCE_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_PRE_PAYMENT]: (state: ManageLaundryRoomStore, action) => {
        state.loading = true;
        state.errors = [];
    },
    [UPDATE_LOCATION_PRE_PAYMENT_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_PRE_PAYMENT_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH]: (state: ManageLaundryRoomStore) => {
        state.loading = true;
        state.errors = [];
    },
    [UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_SUPPORTS_SCAN_2_WASH_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY]: (
        state: ManageLaundryRoomStore
    ) => {
        state.loading = true;
        state.errors = [];
    },
    [UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_SUCCESS]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UPDATE_LOCATION_SUPPORTS_NOT_MY_LAUNDRY_FAILURE]: (
        state: ManageLaundryRoomStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
});
